import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogHeader,
  AlertDialogTitle,
  CloseIcon,
} from '@pebl/ui';
import * as VisuallyHidden from '@radix-ui/react-visually-hidden';
import clsx from 'clsx';
import { useMemo, useState } from 'react';

import { mapPreventRedirect } from '@/features/actions/utils/map-prevent-redirect';
import { TranslationComponentProps } from '@/types/translation';
import { hashAction } from '@/utils/hash-action';
import log from '@/utils/logging';

import ActionForm from '../../action-form';
import { ActionListButton } from './action-list-button';
import { ActionListPropCallback, CommonActionListDisplayProps } from './types';

export type ActionListDialogProps = {
  /**
   * Optionally provide class names to add to the Dialog container
   * used to render the action form (only applicable if `displayMethod` is set to `dialog`)
   */
  dialogClassName?: string;

  /**
   * Whether the form for an clicked/opened action should be displayed in
   * a full screen mode dialog.
   *
   * (Only applies if `displayMethod` is set to `dialog`)
   *
   * If `true`, the form will be displayed in full screen mode.
   * If `false`, the form will be displayed in a modal.
   *
   * If a regular expression is provided, the form will be displayed
   * in full screen mode if the action type matches the regular expression.
   *
   * Default - if nothing is provided, the form will be displayed in a modal.
   */
  fullScreen?: boolean | RegExp | ActionListPropCallback;

  /**
   * If fullScreen is true or the RegExp passes and the form is displayed in
   * a fullscreen dialog, this prop allows us to optionally show a close button
   * in the top right corner of the dialog.
   *
   * If fullScreen is unset, false or the RegExp fails then this prop is ignored.
   *
   * Default = false
   */
  fullScreenCloseButton?: boolean;
} & CommonActionListDisplayProps;

/**
 * ⚠️ DO NOT USE THIS COMPONENT DIRECTLY - THIS IS AN INTERNAL COMPONENT
 *
 * Instead use the {@link ActionList} or {@link ActionListLoader} components if you
 * want to display a list of actions or {@link ActionForm} or {@link ActionFormLoader}
 * if you want to display a single action form.
 */
export function ActionListDialog({
  actionFormClassName,
  actions,
  activeInstantAction,
  closeCancelsAction,
  dialogClassName,
  fullScreen,
  fullScreenCloseButton,
  hideTitle,
  isPending,
  onActionCancelled,
  onActionChained,
  onActionError,
  onActionSuccess,
  preventRedirect,
  selectedAction,
  setSelectedAction,
  t,
}: ActionListDialogProps & TranslationComponentProps) {
  const [chainedActionTitle, setChainedActionTitle] = useState<
    string | undefined
  >(undefined);
  const dialogTitle = chainedActionTitle ?? selectedAction?.title ?? '';

  // This is memoized to avoid re-running the regex or callback on every render unnecessarily
  const isDialogFullscreen = useMemo(() => {
    if (fullScreen === true) {
      return true;
    }

    if (typeof fullScreen === 'function' && selectedAction) {
      try {
        return fullScreen(selectedAction);
      } catch (error) {
        log.debug(
          '⚠️ ActionListDialog: Error checking if action should be full screen',
          error,
        );
        return false;
      }
    }

    if (fullScreen instanceof RegExp && selectedAction) {
      try {
        return fullScreen.test(selectedAction.type);
      } catch (error) {
        log.debug(
          '⚠️ ActionListDialog: Error checking if action should be full screen',
          error,
        );
        return false;
      }
    }

    return false;
  }, [fullScreen, selectedAction]);

  return (
    <AlertDialog
      open={!!selectedAction}
      onOpenChange={(open) => {
        if (!open) {
          if (closeCancelsAction) {
            log.debug(
              'ActionListDialog: Dialog closed, calling onActionCancelled callback',
            );
            onActionCancelled?.(selectedAction);
          }
          setSelectedAction(undefined);
        }
      }}
    >
      {actions?.map((action) => (
        <ActionListButton
          wrapperType="dialog"
          key={action.key ?? hashAction(action)}
          action={action}
          activeInstantAction={activeInstantAction}
          // selectedAction={selectedAction}
          setSelectedAction={setSelectedAction}
          isPending={isPending && activeInstantAction === action}
        />
      ))}
      {/**
       * NOTE: We may want to add a class that includes the action type for action specific
       * targeting e.g. action-list-dialog-shop.add
       * The ActionForm should already do this for the form, but we wouldn't have any way
       * to customise the actual Dialog per action (if that's even a thing).
       */}
      <AlertDialogContent
        className={clsx(
          `qng-action-list-dialog flex flex-col gap-2 overflow-y-auto`,
          isDialogFullscreen
            ? 'qng-action-list-dialog-fullscreen size-full max-h-full max-w-full rounded-none'
            : `
              max-h-[calc(100dvh-2rem)] max-w-[calc(100dvw-2rem)]

              sm:max-w-lg
            `,
          dialogClassName,
        )}
      >
        <AlertDialogHeader>
          {hideTitle ? (
            <VisuallyHidden.Root>
              <AlertDialogTitle></AlertDialogTitle>
            </VisuallyHidden.Root>
          ) : (
            <AlertDialogTitle className="text-left">
              {dialogTitle}
            </AlertDialogTitle>
          )}
          {/* This DialogDescription is required to prevent warnings, even if we don't use it */}
          <VisuallyHidden.Root>
            <AlertDialogDescription />
          </VisuallyHidden.Root>

          {fullScreenCloseButton && isDialogFullscreen && (
            <div
              className="qng-action-list-dialog-close-button absolute right-6 top-2 cursor-pointer"
              onClick={() => {
                setSelectedAction(undefined);
                onActionCancelled?.(selectedAction);
              }}
            >
              <CloseIcon className="hover:text-gray-400" />
            </div>
          )}
        </AlertDialogHeader>
        {selectedAction && (
          /*
           * Action forms normally show the title, but we can hide it here
           * if the dialog is showing the header instead.
           */
          <ActionForm
            action={selectedAction}
            className={actionFormClassName}
            hideTitle={!hideTitle}
            preventRedirect={mapPreventRedirect(
              preventRedirect,
              selectedAction,
            )}
            onActionError={(error) => onActionError?.(error, selectedAction)}
            onActionChained={(_previousAction, nextAction) => {
              log.debug(
                'ActionList:onActionChained - Action form indicated chaining',
              );
              setChainedActionTitle(nextAction.title);
              onActionChained?.(_previousAction, nextAction);
            }}
            onActionSuccess={(executionResponse) => {
              log.debug(
                'ActionList:onActionSuccess - Action form indicated success',
              );
              // Close the dialog (this feels a bit sketchy)
              setSelectedAction(undefined);

              // Call the parent's onActionSuccess callback
              onActionSuccess?.(executionResponse, selectedAction);
            }}
            onActionCancelled={() => {
              setSelectedAction(undefined);
              onActionCancelled?.(selectedAction);
            }}
          />
        )}

        {/* AlertDialog does not have a top-corner close button */}
        {selectedAction && !selectedAction.cancel && (
          <AlertDialogCancel
            className="min-h-12"
            onClick={() => {
              if (closeCancelsAction) {
                onActionCancelled?.(selectedAction);
              }
              setSelectedAction(undefined);
            }}
          >
            {t('common.dialog.close_button')}
          </AlertDialogCancel>
        )}
      </AlertDialogContent>
    </AlertDialog>
  );
}
