import { useIsFetching } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useGetBasket } from '@/features/shop/api/use-get-basket';
import {
  addNotification,
  clearNotificationsWithContext,
} from '@/stores/qng-data-store';
import { useSelectQngAuth } from '@/stores/selectors';
import { TranslationComponentProps } from '@/types/translation';

import { useGetRoot } from '../../../../common-queries/use-get-root';
import { Sidebar } from '../side-bar';
import { HeaderBarView } from './header-bar-view';

type HeaderBarDataProps = {} & TranslationComponentProps;

export function HeaderBarData({ t }: HeaderBarDataProps) {
  const { authToken, isAuthTokenAnonymous } = useSelectQngAuth();
  const { data, isRefetching } = useGetRoot({
    authToken,
    isAuthTokenAnonymous,
  });

  /*
   * For now we are showing a global refetching indicator, it isn't necessarily
   * the nicest or most targetted way, but we decided is good enough for the time
   * being with our current pilot goals.
   */
  const isFetching = useIsFetching();

  const [open, setOpen] = useState<boolean>(false);

  /*
   * A fairly horrible hack to close the sidebar when the route changes
   * https://github.com/radix-ui/primitives/issues/1301
   */
  const { pathname } = useLocation();
  useEffect(() => {
    setOpen(false);
  }, [pathname]);

  const title = data?.client_data?.header;
  const username = data?.data?.user;

  // This is currently only used for no operational day messages
  const bannerText = data?.client_data?.description;
  useEffect(() => {
    if (bannerText) {
      addNotification(
        {
          message: bannerText,
          type: 'informational',
          context: 'header.banner',
        },
        { notToShowAgainFor: dayjs.duration(5, 'minutes') },
      );
    } else {
      clearNotificationsWithContext('header.banner');
    }
  }, [bannerText]);

  const { data: basketData } = useGetBasket({ authToken });
  /*
   * Items is an array of items, of which each item is a type of purchasable item, and is grouped as such.
   * So the top level item_count is the total number of unique items in the basket, not the total number of items.
   */
  const basketItemCount = basketData?.items?.reduce((acc, item) => {
    return acc + item.data.quantity;
  }, 0);

  return (
    <HeaderBarView
      open={open}
      setOpen={setOpen}
      title={title ?? undefined}
      sidebarTitle={username}
      basketItemCount={basketItemCount}
      showLoadingIndicator={isFetching > 0}
      refetching={isRefetching}
      t={t}
      data-username={username}
      data-title={title}
      data-session-active={data?.data?.active ?? false}
      data-operationalday-available={data?.data?.available ?? false}
      data-session-source={data?.data?.session_source ?? 'no session'}
    >
      <Sidebar />
    </HeaderBarView>
  );
}
