import { useGetRoot } from '@/common-queries/use-get-root';
import { useSelectQngAuth } from '@/stores/selectors';
import { TranslationComponentProps } from '@/types/translation';

import { ShopItemView } from './shop-item-view';

type ShopItemDataProps = {} & TranslationComponentProps;

export function ShopItemData({ t }: ShopItemDataProps) {
  const { authToken, isAuthTokenAnonymous } = useSelectQngAuth();

  const { data } = useGetRoot({
    authToken,
    isAuthTokenAnonymous,
  });

  const available = data?.data?.available;

  if (!available) {
    return false;
  }

  return <ShopItemView t={t} />;
}
