import { Label, Typography } from '@pebl/ui';
import clsx from 'clsx';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';

import { Slider } from '@/components/slider';

import { CommonActionPropertyViewProps } from '../../types';
import { formatTimeFromNow } from './util/format-from-now';

export function ActionPropertyTimeSliderView({
  disabled,
  error,
  helperText,
  initialValue,
  label,
  maximum,
  minimum,
  name,
  onChange,
  value,
}: {
  error?: boolean;
  label?: string;
  helperText?: string;
  minimum: Dayjs;
  maximum: Dayjs;
  initialValue?: number;
} & CommonActionPropertyViewProps<Dayjs>) {
  const { t } = useTranslation();

  return (
    <div className="qng-action-property-time-slider">
      <Label
        htmlFor={name}
        className={`
          qng-action-property-time-slider-label font-semibold

          ${error ? 'text-red-500' : ''}
        `}
      >
        {label}
      </Label>
      <div className="slider-container py-2 text-center">
        <Typography variant="body2">
          {value?.format('h:mm A') ?? t('common.unknown_slider_value')}
        </Typography>
        <div className="slider px-4 py-2">
          <Slider
            id={name}
            defaultValues={[initialValue ?? minimum.valueOf()]}
            min={minimum.valueOf()}
            max={maximum.valueOf()}
            step={60 * 1000}
            disabled={disabled}
            onChange={(values) =>
              values.length > 0 && onChange?.(dayjs(values[0]))
            }
            className="qng-action-property-time-slider-input"
            thumbClass="bg-primary"
            trackClass="bg-primary-dark"
            rangeClass="bg-primary-light"
            /*
             *
             * Data Fields - Primarily for debugging / testing
             *
             */
            data-action-option-type="time"
            data-action-option-format="slider"
          />
        </div>
        <Typography variant="body2" className="slider-time-from-now">
          {formatTimeFromNow(t, minimum, value)}
        </Typography>
      </div>
      {helperText && (
        <Typography
          variant="body2"
          className={clsx(
            'qng-action-property-time-slider-helper-text',
            error ? 'text-red-500' : '',
          )}
        >
          {helperText}
        </Typography>
      )}
    </div>
  );
}
