import dayjs from 'dayjs';
import { Controller, useFormContext } from 'react-hook-form';

import { ApiResourceActionOptionsProperty } from '@/api/types';
import { mapActionOptionsPropertyToRules } from '@/features/actions/utils/map-action-property-rules';

import { CommonActionPropertyOptions } from '../../types';
import { ActionPropertyTimeSliderView } from './time-slider-view';

type ActionPropertyTimeSliderProps = {
  id: string;
  data: ApiResourceActionOptionsProperty;
  options: CommonActionPropertyOptions;
};

export function ActionPropertyTimeSlider({
  data,
  id,
  options,
}: ActionPropertyTimeSliderProps) {
  const { errorMessage, required } = options;
  const { max, min } = data;

  const { control } = useFormContext();

  const minimum = dayjs(min as string);
  const maximum = dayjs(max as string);

  return (
    <div key={id} className="action-form-option">
      <Controller
        name={id}
        rules={mapActionOptionsPropertyToRules(data, required)}
        control={control}
        defaultValue={minimum}
        render={({ field }) => (
          <ActionPropertyTimeSliderView
            label={data.title}
            minimum={minimum}
            maximum={maximum}
            onChange={field.onChange}
            onBlur={field.onBlur}
            name={field.name}
            value={field.value}
            disabled={field.disabled}
            helperText={errorMessage ?? data.description}
          />
        )}
      />
    </div>
  );
}
