import * as RadixSlider from '@radix-ui/react-slider';
import clsx from 'clsx';

type SliderProps = {
  // The element id of the slider
  id?: string;

  // The default values of the slider
  defaultValues?: number[];

  // Whether the slider is disabled
  disabled?: boolean;

  // The maximum value of the slider
  max?: number;

  // The minimum value of the slider
  min?: number;

  // The step value of the slider
  step?: number;

  // The class name of the slider root
  className?: string;

  // The class name of the track
  trackClass?: string;

  // The class name of the thumb
  thumbClass?: string;

  // The class name of the range
  rangeClass?: string;

  // The function to call when the slider value changes
  onChange?: (values: number[]) => void;
};

export function Slider({
  className,
  defaultValues,
  disabled,
  id,
  max,
  min,
  onChange,
  rangeClass,
  step,
  thumbClass,
  trackClass,
}: SliderProps) {
  return (
    <RadixSlider.Root
      id={id}
      className={clsx(
        className,
        `qng-slider-root relative flex h-1 w-full touch-none select-none items-center rounded-full`,
      )}
      defaultValue={defaultValues}
      min={min}
      max={max}
      disabled={disabled}
      step={step}
      onValueChange={onChange}
    >
      <RadixSlider.Track
        className={clsx(
          'qng-slider-track relative h-1 grow rounded-full bg-black',
          trackClass,
        )}
      >
        <RadixSlider.Range
          className={clsx(
            'qng-slider-range absolute h-full rounded-full',
            rangeClass,
          )}
        />
      </RadixSlider.Track>
      <RadixSlider.Thumb
        className={clsx(
          'qng-slider-thumb block size-4 rounded-full shadow-slider-thumb',
          thumbClass,
        )}
        style={disabled ? { backgroundColor: 'light-gray' } : {}}
      />
    </RadixSlider.Root>
  );
}
