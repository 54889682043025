import dayjs, { Dayjs } from 'dayjs';

import { TranslationComponentProps } from '@/types/translation';

export const formatTimeFromNow = (
  t: TranslationComponentProps['t'],
  minimum?: Dayjs,
  value?: Dayjs,
) => {
  if (!minimum || !value) return '';
  const d = dayjs.duration(value.diff(minimum));
  const hours = d.hours();
  const minutes = d.minutes();
  const hoursText = hours > 1 ? t('common.time.hours') : t('common.time.hour');
  const showFromNow = hours > 0 || minutes > 0;
  return `${hours > 0 ? hours + ' ' + hoursText + ' ' : ''}${minutes > 0 ? minutes + ' ' + t('common.time.minutes') + ' ' : ''}${showFromNow ? t('common.from_now') : ''}`.trim();
};
