import {
  BarcodeDetector,
  Button,
  CameraIcon,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogTitle,
  DialogTrigger,
  Input,
} from '@pebl/ui';
import { useState } from 'react';

import { CommonActionPropertyViewProps } from '../../../types';

export function ActionPropertyInputBarcodeView({
  defaultValue,
  dialogCloseButtonText,
  disabled,
  error,
  helperText,
  innerRef,
  label,
  name,
  onBlur,
  onChange,
  value,
}: {
  error?: boolean;
  label?: string;
  helperText?: string;
  dialogCloseButtonText?: string;
} & CommonActionPropertyViewProps<string>) {
  /*
   * So we can programmatically close the dialog once a barcode is scanned
   * we have to control the dialog open state ourselves.
   */
  const [open, setOpen] = useState<boolean>(false);

  /*
   * We also have to keep an internal state for the value of the input
   * so we can update it when a barcode is scanned
   */
  const [inputValue, setInputValue] = useState<string>(value ?? '');

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <Input
        type="text"
        label={label}
        error={error}
        name={name}
        value={inputValue}
        defaultValue={defaultValue}
        disabled={disabled}
        onChange={(e) => {
          setInputValue(e.target.value);
          onChange?.(e.target.value);
        }}
        onBlur={onBlur}
        helperText={helperText}
        className="qng-action-property-input-barcode"
        ref={innerRef}
        // Data Fields - Primarily for debugging / testing
        data-action-option-type="string"
        data-action-option-format="barcode"
        suffixIcon={
          <DialogTrigger disabled={disabled}>
            <CameraIcon />
          </DialogTrigger>
        }
      />
      <DialogContent>
        {/* DialogTitle required, even though it's empty to avoid errors */}
        <DialogTitle></DialogTitle>
        <DialogDescription></DialogDescription>
        <BarcodeDetector
          showOverlay
          onBarcodeDetected={(barcodes) => {
            if (barcodes) {
              const scannedBarcode = barcodes[0].decodedText;
              setInputValue(scannedBarcode);
              onChange?.(scannedBarcode);
              setOpen(false);
            }
          }}
        />
        {dialogCloseButtonText && (
          <DialogFooter>
            <Button onClick={() => setOpen(false)}>
              {dialogCloseButtonText}
            </Button>
          </DialogFooter>
        )}
      </DialogContent>
    </Dialog>
  );
}
